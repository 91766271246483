import { configureStore } from "@reduxjs/toolkit";
import UserSlice from "./slices/userSlice";
import VentureSlice from "./slices/ventureSlice";
import FullScreenMode from "./slices/fullScreenMode";
import FinancialData from "./slices/financialData";
import BussinessServiceOffering from "./slices/bussinessServiceOffering";
import BussinessSolutions from "./slices/bussinessSolution";
import OperationsFunctionLevel from "./slices/operationsFunctionLevel";
import operationOrgGovernance from "./slices/operationOrgGovernance";
import Permission from "./slices/permission";
import CommentMode from "./slices/commentMode";
import venturePermission from "./slices/venturePermission";
import businessCanvas from "./slices/businessCanvas";

const store = configureStore({
  reducer: {

    user                    : UserSlice.reducer,
    venture                 : VentureSlice.reducer,
    permission              : Permission.reducer,
    FullScreenMode          : FullScreenMode.reducer,
    FinancialData           : FinancialData.reducer,
    BussinessServiceOffering: BussinessServiceOffering.reducer,
    BussinessSolutions      : BussinessSolutions.reducer,
    OperationsFunctionLevel : OperationsFunctionLevel.reducer,
    operationOrgGovernance  : operationOrgGovernance.reducer,
    CommentMode             : CommentMode.reducer,
    venturePermission       : venturePermission.reducer,
    businessCanvas          : businessCanvas.reducer

  }
});

export default store;
