import { createSlice } from "@reduxjs/toolkit";

interface FullScreenModeType {
 isFullScreenMode?: boolean
}

interface AppFullScreenModeType {
  FullScreenMode: FullScreenModeType
 }

const initialStateValues: FullScreenModeType = {
  isFullScreenMode: false

};

// set data functions

const setIsFullScreenMode = (state: any, actions: any) => {

  state.isFullScreenMode = actions.payload;

};

const FullScreenMode = createSlice({

  name: "FullScreenMode",

  initialState: initialStateValues,

  reducers: {
    setIsFullScreenMode: setIsFullScreenMode
  }
});

export const getIsFullScreenMode = (state: AppFullScreenModeType) => state.FullScreenMode.isFullScreenMode;

export default FullScreenMode;
