import { createSlice } from "@reduxjs/toolkit";

interface functionLevel {
      functionName : string,
      goals : string,
      responsibility: string,
      targets : string,
      outcome : string,
      comments : string,

}

interface FunctionLevelTypes {
   functionLevel: functionLevel
 }

const initialStateValues: FunctionLevelTypes = {
  functionLevel:
      {
        functionName  : "Organization",
        goals         : "",
        responsibility: "",
        targets       : "",
        outcome       : "",
        comments      : ""
      }

};

// set data functions

const setFunctionLevel = (state: any, actions: any) => {

  state.functionLevel = actions.payload;

};

const OperationsFunctionLevel = createSlice({

  name: "FunctionLevel",

  initialState: initialStateValues,

  reducers: {
    setFunctionLevel: setFunctionLevel
  }
});

export const getFunctionLevelData = (state: any) => {

  return state.OperationsFunctionLevel.functionLevel;

};

export default OperationsFunctionLevel;
