import { createSlice } from "@reduxjs/toolkit";

interface OrgFunctionLevel {
   funtionName: string,
   initiatives: [
     {
       initiativeName: string,
       updates : string,
       challenges:string,
       action : string
     }
   ]

}

interface OrgFunctionLevelTypes {
   orgFunctionLevel: OrgFunctionLevel
 }

const initialStateValues: OrgFunctionLevelTypes = {
  orgFunctionLevel:
      {
        funtionName: "",
        initiatives: [
          {
            initiativeName: "",
            updates       : "",
            challenges    : "",
            action        : ""
          }
        ]
      }

};

// set data functions

const setOrgFunctionLevel = (state: any, actions: any) => {

  state.orgFunctionLevel = actions.payload;

};

const operationOrgGovernance = createSlice({

  name: "FunctionLevel",

  initialState: initialStateValues,

  reducers: {
    setOrgFunctionLevel: setOrgFunctionLevel
  }
});

export const getOrgFunctionLevelData = (state: any) => {

  return state.operationOrgGovernance.orgFunctionLevel;

};

export default operationOrgGovernance;
