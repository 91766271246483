import React from "react";
import config from "../../configs/config";
// mui
import { Button, Divider, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import Grow from "@mui/material/Grow";
// mui icons
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import styles from "./LandingPage.module.css";
import rocketIcon from "../../assets/images/rocket.png";


const LandingPage = () => {


  // const history = useNavigate();
  return (
    <div id={styles.maingrid}>
      <Box id={styles.box1}>
        <Box>
          <Typography variant="body2" id={styles.title1}>
            TODAC 360
          </Typography>
        </Box>
        <Box id={styles.grid2}>
          <Typography id={styles.textwithclick}>about </Typography>
          <Divider orientation="vertical" id={styles.dividerstyle} />
          <Typography id={styles.textwithclick}>pricing </Typography>
          <Divider orientation="vertical" id={styles.dividerstyle} />

          <Typography id={styles.textwithclick}>blog </Typography>
          <Divider orientation="vertical" id={styles.dividerstyle} />

          <Typography id={styles.textwithclick}>contact </Typography>
          <Button
            onClick={() => {

              window.location.href = config.routes.login;
            
            }}
            id={styles.loginbutton1}
          >
            <Typography id={styles.loginbuttontext}>Login</Typography>
          </Button>
        </Box>
      </Box>
      <Box sx={{ height: "79vh" }}>
        <Grid container sx={{ height: "100%" }}>
          <Grid xs={7} id={styles.grid3}>
            <Box id={styles.gridbox1}>
              <Grow
                in={true}
                style={{ transformOrigin: "0 0 0" }}
                {...({ timeout: 1000 })}
              >
                <Typography id={styles.bigtext}>Bringing your</Typography>
              </Grow>
              <Grow
                in={true}
                style={{ transformOrigin: "0 0 0" }}
                {...({ timeout: 2500 })}
              >
                <Typography id={styles.bigtext}>ideas to life</Typography>
              </Grow>

              <Box id={styles.gridbox2} style={{ marginTop: "5.85vw" }}>
                <Button
                  id={styles.buttonorangecontained}
                  onClick={() => {

                    window.location.href = config.routes.register;
                  
                  }}
                >
                  <Typography id={styles.textmediumwhite}>Sign Up</Typography>
                </Button>
                <Button id={styles.buttonorangeoutlined}>
                  <PlayArrowIcon sx={{ color: "#FF721A", fontSize: "3vw" }} />
                  <Typography id={styles.textmediumblack}>
                    watch demo
                  </Typography>
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid xs={5} id={styles.gridbox2}>
            <img
              src={rocketIcon}
              id={styles.img}
              alt="rocket"
            ></img>
          </Grid>
        </Grid>
      </Box>
    </div>
  );

};

export default LandingPage;
