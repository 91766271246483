// import firebase from "firebase/app"
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey           : process.env.REACT_APP_APIKEY,
  authDomain       : process.env.REACT_APP_AUTHDOMAIN,
  projectId        : process.env.REACT_APP_PROJECTID,
  storageBucket    : process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId            : process.env.REACT_APP_APP_ID,
  measurementId    : process.env.REACT_APP_MEASUREMENT_ID
};

// initialize firebase app
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export default app;

