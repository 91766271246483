import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

interface serviceOffering {

  serviceName : string,
  serviceOffering : string,
  outcome : string,
  cost : string,
  timeFrame : string,
  keyActivities : string,
  engagementOutCome : string,
  valueLevers : string,
  customerContribution : string,
  companyOverviewBoilerPlate: string,
  positioningStatement : string,
  companyName : string,
  contact : string,
  webSite : string,
  email : string,
  engagementOutComeTitle : string,
  servicePackageTitle : string,
  companyNameTitle : string,
  keyActivitiesTitle : string,
  valueLeversTitle : string,
  solutionApproachTitle : string,
  customerContributionTitle : string,
  packageLogo : string,
  approachImage : string,
  servicePackageId : string

}

interface ServiceOfferingTypes {
  serviceOffering: serviceOffering
 }


const initialStateValues: ServiceOfferingTypes = {
  serviceOffering:
    {
      serviceName               : "",
      serviceOffering           : "",
      outcome                   : "",
      cost                      : "",
      timeFrame                 : "",
      keyActivities             : "",
      engagementOutCome         : "",
      valueLevers               : "",
      customerContribution      : "",
      companyOverviewBoilerPlate: "",
      positioningStatement      : "",
      companyName               : "",
      contact                   : "",
      webSite                   : "",
      email                     : "",
      engagementOutComeTitle    : "",
      servicePackageTitle       : "",
      companyNameTitle          : "",
      keyActivitiesTitle        : "",
      valueLeversTitle          : "",
      solutionApproachTitle     : "",
      customerContributionTitle : "",
      packageLogo               : "",
      approachImage             : "",
      servicePackageId          : uuidv4()
    }

};

// set data functions

const setServiceOffering = (state: any, actions: any) => {

  state.serviceOffering = actions.payload;

};

const BussinessServiceOffering = createSlice({

  name: "ServiceOffering",

  initialState: initialStateValues,

  reducers: {
    setServiceOffering: setServiceOffering
  }
});

export const getServiceOfferingData = (state: any) => state.BussinessServiceOffering.serviceOffering;

export default BussinessServiceOffering;
