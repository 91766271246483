import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
// import config from "../../configs/config";

interface FinancialDataModuleType {
   onROllTeam?: any,
   pipeAndBillData?: any,
   accLevelSumData?: any,
   bizOpsSumData?: any,
   plOutlookData?: any
  }
  
  interface FinancialDataType {
   FinancialData?: FinancialDataModuleType
  }

const initialStateValues: FinancialDataModuleType = {

  onROllTeam     : {},
  pipeAndBillData: {},
  accLevelSumData: {},
  bizOpsSumData  : {},
  plOutlookData  : {}
 
};

// set data functions

const setOnROllTeam = (state: any, actions: any) => {

  const onRollData = actions.payload;

  const onRollEntries: any = state.onROllTeam || {};
                
  onRollData.data.map((onRollEntry: any) => {
  
    if (_.isEmpty(onRollEntries[onRollEntry.year]) ) {onRollEntries[onRollEntry.year] = {};}

    // const monthName = config.financialMonthByNumber[onRollEntry.month].slice(0, 3);

    if (_.isEmpty(onRollEntries[onRollEntry.year][onRollEntry.month]) ) {onRollEntries[onRollEntry.year][onRollEntry.month] = {};}

    onRollEntries[onRollEntry.year][onRollEntry.month] = onRollEntry.entries;

  });

  state.onROllTeam = onRollEntries;
 
};

const setPipeAndBillData = (state: any, actions: any) => {

  const pipeAndBillData = actions.payload;

  // console.info(pipeAndBillData);

  const pipeAndBillEntries: any = state.pipeAndBillData;

  if (_.isEmpty(pipeAndBillEntries[pipeAndBillData.year]) ) {pipeAndBillEntries[pipeAndBillData.year] = {};}

  pipeAndBillEntries[pipeAndBillData.year] = pipeAndBillData.entries;

  state.pipeAndBillData = pipeAndBillEntries;
 
};

const setAccLevelSumData = (state: any, actions: any) => {

  const accSummData = actions.payload;

  state.accLevelSumData = accSummData.entries;
 
};

const setBizOpsSumData = (state: any, actions: any) => {

  const bizOpsSummData = actions.payload;

  const bizOpsSummEntries: any = state.bizOpsSumData;

  if (_.isEmpty(bizOpsSummEntries[bizOpsSummData.year]) ) {bizOpsSummEntries[bizOpsSummData.year] = {};}

  bizOpsSummEntries[bizOpsSummData.year] = bizOpsSummData.entries;

  state.bizOpsSumData = bizOpsSummEntries;
 
};

const setPlOutlookData = (state: any, actions: any) => {
  
  const plOutlookData = actions.payload;

  const plOutlookEntries: any = state.plOutlookData;
  
  plOutlookData.map((yearData: any) => {
      
    plOutlookEntries[yearData.data.year] = yearData.data.entries;

  });

  state.plOutlookData = plOutlookEntries;
 
};

const setFinancialData = (state: any, actions: any) => {
    
  state.onROllTeam = {};
  state.pipeAndBillData = {};
  state.accLevelSumData = {};
  state.bizOpsSumData = {};
  state.plOutlookData = {};

};

const FinancialData = createSlice({

  name: "financialData",
 
  initialState: initialStateValues,
 
  reducers: {
    setOnROllTeam     : setOnROllTeam,
    setPipeAndBillData: setPipeAndBillData,
    setAccLevelSumData: setAccLevelSumData,
    setBizOpsSumData  : setBizOpsSumData,
    setPlOutlookData  : setPlOutlookData,
    setFinancialData  : setFinancialData
  }

});

export const getOnROllTeam = (state: FinancialDataType) => state.FinancialData?.onROllTeam;
export const getPipeAndBillData = (state: FinancialDataType) => state.FinancialData?.pipeAndBillData;
export const getAccLevelSumData = (state: FinancialDataType) => state.FinancialData?.accLevelSumData;
export const getBizOpsSumData = (state: FinancialDataType) => state.FinancialData?.bizOpsSumData;
export const getPlOutlookData = (state: FinancialDataType) => state.FinancialData?.plOutlookData;
export const getFinancialData = (state: FinancialDataType) => state.FinancialData;

export default FinancialData;
