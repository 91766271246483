import { createSlice } from "@reduxjs/toolkit";
import config from "../../configs/config";

interface UserType {
  permissionSlice?: any
  permissionLoaded?: boolean
}

interface AppUserType {
 venturePermission: UserType
}

const initialStateValues: UserType = {
  permissionSlice : config.initialPermission,
  permissionLoaded: false
   
};

// set data functions

const setVenturePermission = (state: any, actions: any) => {

  if (actions.payload == "EMPTY") {

    state.permissionSlice = initialStateValues.permissionSlice;
    state.permissionLoaded = false;

    return;

  } else {

    state.permissionSlice = actions.payload;
    state.permissionLoaded = true;
    
  }

};


const venturePermission = createSlice({

  name: "venturePermission",

  initialState: initialStateValues,

  reducers: {
    setVenturePermission: setVenturePermission
  }
});

export const getVenturePermission = (state: AppUserType) => state.venturePermission?.permissionSlice;
export const getPermissionLoaded = (state: AppUserType) => state.venturePermission?.permissionLoaded;


export default venturePermission;
