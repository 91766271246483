import { createSlice } from "@reduxjs/toolkit";

interface UserType {
 firstName?: string,
 lastName?: string,
 email?: string,
 subscription?: string,
 role?: string,
 profileIcon?: string,
 userVerified?: boolean,
 ventures?: [],
 sharedVentures?: [],
 userId?: string
}

interface AppUserType {
 user: UserType
}

const initialStateValues: UserType = {
  firstName     : "",
  lastName      : "",
  email         : "",
  subscription  : "",
  role          : "",
  profileIcon   : "",
  userVerified  : false,
  userId        : "",
  ventures      : [],
  sharedVentures: []

};

// set data functions

const setFirstName = (state: any, actions: any) => {

  state.firstName = actions.payload;

};
const setLastName = (state: any, actions: any) => {

  state.lastName = actions.payload;

};
const setEmail = (state: any, actions: any) => {

  state.email = actions.payload;

};
const setSubscription = (state: any, actions: any) => {

  state.subscription = actions.payload;

};

const setRole = (state: any, actions: any) => {

  state.role = actions.payload;

};
const setProfileIcon = (state: any, actions: any) => {

  state.profileIcon = actions.payload;

};
const setUserVerified = (state: any, actions: any) => {

  state.userVerified = actions.payload;

};
const setUserId = (state: any, actions: any) => {

  state.userId = actions.payload;

};
const setVentures = (state: any, actions: any) => {

  state.ventures = actions.payload;

};

const setAddVentures = (state: any, actions: any) => {

  const ventures: any = state.ventures;

  ventures.push(actions.payload);

  state.ventures = ventures;


};

const setSharedVentures = (state: any, actions: any) => {

  state.sharedVentures = actions.payload;

};

const setUser = (state: any, actions: any) => {

  state.firstName = actions.payload.firstName;
  state.lastName = actions.payload.lastName;
  state.email = actions.payload.email;
  state.subscription = actions.payload.subscription;
  state.role = actions.payload.role;
  state.profileIcon = actions.payload.profileIcon;
  state.userVerified = actions.payload.userVerified;
  state.ventures = actions.payload.ventures;
  state.sharedVentures = actions.payload.sharedVentures;
  state.userId = actions.payload.userId;

};

const UserSlice = createSlice({

  name: "User",

  initialState: initialStateValues,

  reducers: {
    setFirstName     : setFirstName,
    setLastName      : setLastName,
    setEmail         : setEmail,
    setSubscription  : setSubscription,
    setRole          : setRole,
    setProfileIcon   : setProfileIcon,
    setUserVerified  : setUserVerified,
    setUserId        : setUserId,
    setVentures      : setVentures,
    setSharedVentures: setSharedVentures,
    setUser          : setUser,
    setAddVentures   : setAddVentures
  }
});

export const getFirstName = (state: AppUserType) => state.user?.firstName;
export const getLastName = (state: AppUserType) => state.user?.lastName;
export const getEmail = (state: AppUserType) => state.user?.email;
export const getSubscription = (state: AppUserType) => state.user?.subscription;
export const getRole = (state: AppUserType) => state.user?.role;
export const getProfileIcon = (state: AppUserType) => state.user?.profileIcon;
export const getUserVerified = (state: AppUserType) => state.user?.userVerified;
export const getUserId = (state: AppUserType) => state.user?.userId;
export const getVentures = (state: AppUserType) => state.user?.ventures;
export const getSharedVentures = (state: AppUserType) => state.user?.sharedVentures;
export const getUser = (state: AppUserType) => state.user;

export default UserSlice;
