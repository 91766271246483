import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

const initialStateValues: any = {
  businessSolutions:
    {
      "solutionId"        : uuidv4(),
      "problemArea"       : "",
      "problemDescription": "",
      "solutionPackage"   : {
        "bigProblem"              : "",
        "packageLogo"             : "",
        "customerPersona"         : "",
        "defineSolution"          : "",
        "keyDifferentitation"     : "",
        "adoptionsDriversSolution": "",
        "outComeSolution"         : "",
        "addressProblem"          : "",
        "useCaseSolution"         : ""
      },
      "solutionMilestones": []
    }

};

// set data functions

const setBusinessSolutions = (state: any, actions: any) => {

  // console.info(actions);

  state.businessSolutions = actions.payload;

};

const BussinessSolutions = createSlice({

  name: "BusinessSolutions",

  initialState: initialStateValues,

  reducers: {
    setBusinessSolutions: setBusinessSolutions
  }
});

export const getBusinessSolutionsData = (state: any) => state.BussinessSolutions.businessSolutions;

export default BussinessSolutions;
