import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
// import config from "../../configs/config";

interface SectionType {
  salesActivities: any,
  salesActivitiesLoaded: boolean,
  keyResources: any,
  keyResourcesLoaded: boolean,
  valueProp: any,
  valuePropLoaded: boolean,
  services: any,
  servicesLoaded: boolean,
  solutions: any,
  solutionsLoaded: boolean,
  targetedSegements: any,
  targetedSegementsLoaded: boolean,
  keyPartners: any,
  keyPartnersLoaded: boolean,
  expenseRevenuePlan: any,
  expenseRevenuePlanLoaded: boolean

}

interface BusinessCanvasType {
  businessCanvas: SectionType
}

const initialStateValues: SectionType = {
  salesActivities         : {},
  salesActivitiesLoaded   : false,
  keyResources            : {},
  keyResourcesLoaded      : false,
  valueProp               : [],
  valuePropLoaded         : false,
  services                : [],
  servicesLoaded          : false,
  solutions               : [],
  solutionsLoaded         : false,
  targetedSegements       : [],
  targetedSegementsLoaded : false,
  keyPartners             : [],
  keyPartnersLoaded       : false,
  expenseRevenuePlan      : {},
  expenseRevenuePlanLoaded: false
};

// set data functions

const setSalesActivities = (state: any, actions: any) => {

  state.salesActivities = actions.payload;
  state.salesActivitiesLoaded = true;

};

const setKeyResources = (state: any, actions: any) => {

  const ketResourcesData = actions.payload;

  const keyResourcesEntries: any = state.keyResources || {};
                
  ketResourcesData.data.map((keyResource: any) => {
  
    if (_.isEmpty(keyResourcesEntries[keyResource.year]) ) {keyResourcesEntries[keyResource.year] = {};}

    if (_.isEmpty(keyResourcesEntries[keyResource.year][keyResource.month]) ) {keyResourcesEntries[keyResource.year][keyResource.month] = {};}

    keyResourcesEntries[keyResource.year][keyResource.month] = keyResource.entries;

  });

  state.keyResources = keyResourcesEntries;
  state.keyResourcesLoaded = true;

};

const setValueProp = (state: any, actions: any) => {

  state.valueProp = actions.payload;
  state.valuePropLoaded = true;

};

const setServices = (state: any, actions: any) => {

  state.services = actions.payload;
  state.servicesLoaded = true;

};

const setSolutions = (state: any, actions: any) => {

  state.solutions = actions.payload;
  state.solutionsLoaded = true;

};

const setTargetedSegements = (state: any, actions: any) => {

  state.targetedSegements = actions.payload;
  state.targetedSegementsLoaded = true;

};

const setKeyPartners = (state: any, actions: any) => {

  state.keyPartners = actions.payload;
  state.keyPartnersLoaded = true;

};

const setExpenseRevenuePlan = (state: any, actions: any) => {

  const expenseRevenuePlanData = actions.payload;

  const oldExpenseRevenuePlanData = state.expenseRevenuePlan || {};

  if (_.isEmpty(oldExpenseRevenuePlanData[expenseRevenuePlanData.year]) ) {oldExpenseRevenuePlanData[expenseRevenuePlanData.year] = {};}

  oldExpenseRevenuePlanData[expenseRevenuePlanData.year] = expenseRevenuePlanData.expenseRevenuePlan;

  state.expenseRevenuePlan = oldExpenseRevenuePlanData;
  
  state.expenseRevenuePlanLoaded = true;

};

const setBusinessCanvas = (state: any, actions: any) => {

  if (actions.payload == "EMPTY") {

    state.salesActivities = initialStateValues.salesActivities;
    state.keyResources = initialStateValues.keyResources;
    state.valueProp = initialStateValues.valueProp;
    state.services = initialStateValues.services;
    state.solutions = initialStateValues.solutions;
    state.targetedSegements = initialStateValues.targetedSegements;
    state.keyPartners = initialStateValues.keyPartners;
    state.expenseRevenuePlan = initialStateValues.expenseRevenuePlan;
    state.salesActivitiesLoaded = initialStateValues.salesActivitiesLoaded;
    state.keyResourcesLoaded = initialStateValues.keyResourcesLoaded;
    state.valuePropLoaded = initialStateValues.valuePropLoaded;
    state.servicesLoaded = initialStateValues.servicesLoaded;
    state.solutionsLoaded = initialStateValues.solutionsLoaded;
    state.targetedSegementsLoaded = initialStateValues.targetedSegementsLoaded;
    state.keyPartnersLoaded = initialStateValues.keyPartnersLoaded;
    state.expenseRevenuePlanLoaded = initialStateValues.expenseRevenuePlanLoaded;


    return;

  }

};


const businessCanvas = createSlice({

  name: "businessCanvas",

  initialState: initialStateValues,

  reducers: {
    setSalesActivities   : setSalesActivities,
    setKeyResources      : setKeyResources,
    setValueProp         : setValueProp,
    setServices          : setServices,
    setSolutions         : setSolutions,
    setTargetedSegements : setTargetedSegements,
    setKeyPartners       : setKeyPartners,
    setExpenseRevenuePlan: setExpenseRevenuePlan,
    setBusinessCanvas    : setBusinessCanvas
  }
});

export const getSalesActivities = (state: BusinessCanvasType) => state.businessCanvas?.salesActivities;
export const getSalesActivitiesLoaded = (state: BusinessCanvasType) => state.businessCanvas?.salesActivitiesLoaded;
export const getKeyResources = (state: BusinessCanvasType) => state.businessCanvas?.keyResources;
export const getKeyResourcesLoaded = (state: BusinessCanvasType) => state.businessCanvas?.keyResourcesLoaded;
export const getValueProp = (state: BusinessCanvasType) => state.businessCanvas?.valueProp;
export const getValuePropLoaded = (state: BusinessCanvasType) => state.businessCanvas?.valuePropLoaded;
export const getServices = (state: BusinessCanvasType) => state.businessCanvas?.services;
export const getServicesLoaded = (state: BusinessCanvasType) => state.businessCanvas?.servicesLoaded;
export const getSolutions = (state: BusinessCanvasType) => state.businessCanvas?.solutions;
export const getSolutionsLoaded = (state: BusinessCanvasType) => state.businessCanvas?.solutionsLoaded;
export const getTargetedSegements = (state: BusinessCanvasType) => state.businessCanvas?.targetedSegements;
export const getTargetedSegementsLoaded = (state: BusinessCanvasType) => state.businessCanvas?.targetedSegementsLoaded;
export const getKeyPartners = (state: BusinessCanvasType) => state.businessCanvas?.keyPartners;
export const getKeyPartnersLoaded = (state: BusinessCanvasType) => state.businessCanvas?.keyPartnersLoaded;
export const getExpenseRevenuePlan = (state: BusinessCanvasType) => state.businessCanvas?.expenseRevenuePlan;
export const getExpenseRevenuePlanLoaded = (state: BusinessCanvasType) => state.businessCanvas?.expenseRevenuePlanLoaded;


export default businessCanvas;
