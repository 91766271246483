import bizOpsData from "../Data/bizOpsSummary.json";
import plOutlook from "../Data/PlOutlook.json";

// interface ModulePermissionName {
//   "module": string,
// "businessStrategy": string,
// "financialPlan": string,
// "deliveryOperations": string,
// "gtmSales": string
// }

interface Config {
  backend?: string,
  frontend?: string,
  landingPageUrl?: string,
  unSaveMsg?: string,
  routes?: any,
  lastPageIgnoreList?: string[],
  financialMonthByNumber: any,
  financialMonth: any,
  financialYear?: any,
  financialDropdownYearCount?: any,

  financialCurrency: any,
  financialCurrencySymbol: any,
  financialCurrencyByKey: any,
  financialEmpTypes: any,
  financialEmpTypesByKey: any,
  financialEmpLocation: any,
  financialEmpLocationByKey: any,
  financialEmpBusinessUnits: any,
  financialEmpBusinessUnitsByKey: any,
  financialEmpBusinessUnitsImportByKey: any,

  financialBillable: any,
  financialBillableByKey: any,
  financialOnRollTeamColumn: any,
  financialOnRollTeamColumnByKey: any,

  financialPipelineAndBillingCategory: any,
  financialPipelineAndBillingCategoryByKey: any,

  financialOnROllTeamSampleRecords?: any,

  financialPipelineAndBillingFixedRows?: any,
  financialPipelineAndBillingSampleRecords?: any,

  financialAccountSummaryFixedRows?: any,

  financialBizOpsSummaryFixedRows?: any,
  financialBizOpsSummaryDefaultLabels?: any,

  financialPlOutlookFixedRows?: any
  financialPlOutlookTooltip?: any

  gtmSalesPipeMgtLeadType?: any
  gtmSalesPipeMgtLeadTypeByKey?: any

  gtmSalesPipeMgtLeadStatus?: any
  gtmSalesPipeMgtLeadStatusByKey?: any

  gtmSalesPipeMgtLeadSourcedChannel?: any
  gtmSalesPipeMgtLeadSourcedChannelByKey?: any

  gtmSalesPipeMgtOppoDuration?: any
  gtmSalesPipeMgtOppoDurationByKey?: any

  gtmSalesPipeMgtOppoSize?: any
  gtmSalesPipeMgtOppoSizeByKey?: any

  gtmSalesPipeMgtIndus?: any
  gtmSalesPipeMgtIndusByKey?: any

  gtmSalesPipeMgtCusPersonaType?: any
  gtmSalesPipeMgtCusPersonaTypeByKey?: any

  gtmSalesPipeMgtColumn?: any
  gtmSalesPipeMgtColumnByKey?: any

  gtmSalesPipeMgtChartColor?: any
  
  userWithEditorAccess?: any
  ventureCommenter?: any
  ventureEditor?: any
  moduleName?: any
  inviteCollaboratorsLimit?: any
  freePlanA?: any
  premiumPlanA?: any
  creditsIcons8: string,
  sectionURLWithName?: any,
  modulePermissionName?: any,
  initialPermission?: any,
}

const config: Config = {
  
  backend                   : process.env.REACT_APP_BACKEND_URL || "Backend url problem",
  frontend                  : process.env.REACT_APP_FRONTEND_URL?.slice(0, -1) || "Frontend url problem",
  landingPageUrl            : process.env.REACT_APP_LANDINGPAGE_URL || "",
  unSaveMsg                 : "You have unsaved changes. Do you still want to proceed without saving?",
  financialDropdownYearCount: 3,
  financialYear             : [
    {
      label: "Previous Year",
      value: new Date().getFullYear() - 1
    },
    {
      label: "Current Year",
      value: new Date().getFullYear()
    },
    {
      label: "Forecast Year",
      value: new Date().getFullYear() + 1
    }
  ],
  financialMonth: {
    "january"  : 1,
    "february" : 2,
    "march"    : 3,
    "april"    : 4,
    "may"      : 5,
    "june"     : 6,
    "july"     : 7,
    "august"   : 8,
    "september": 9,
    "october"  : 10,
    "november" : 11,
    "december" : 12
  },

  financialMonthByNumber: {},


  financialCurrency: {
    "usd": "USD",
    "inr": "INR"
  },

  financialCurrencySymbol: {
    "usd": "$",
    "inr": "₹"
  },
  
  financialCurrencyByKey: {},
  
  financialEmpTypes: {
    "per" : "permanent",
    "cont": "contractor"
  },
  financialEmpTypesByKey: {},
  financialEmpLocation  : {
    "off": "offshore",
    "on" : "onshore"
  },
  financialEmpLocationByKey: {},
  financialEmpBusinessUnits: {
    "deli": "Delivery",
    "bd"  : "BizDev",
    "bo"  : "BizOps"
  },
  financialEmpBusinessUnitsByKey      : {},
  financialEmpBusinessUnitsImportByKey: {},
  financialBillable                   : {
    "true" : "billable",
    "false": "nonbillable"
  },
  financialBillableByKey   : {},
  financialOnRollTeamColumn: {

    "Emp ID"                     : "empId",
    "Emp Name"                   : "empN",
    "Designation"                : "desig",
    "Expertise"                  : "expert",
    "Location"                   : "loc",
    "Project Name"               : "pro",
    "Reporting Manager"          : "repMan",
    "Employee Type"              : "empT",
    "Org. Function"              : "bu",
    "Billability"                : "billable",
    "Compensation (Monthly)"     : "monComp",
    "Employee Benefits (Monthly)": "monBene"

  },
  financialOnRollTeamColumnByKey     : {},
  financialPipelineAndBillingCategory: {
    "ext" : "existing",
    "pipe": "pipeline"
  },
  financialPipelineAndBillingCategoryByKey: {},

  gtmSalesPipeMgtLeadType: {
    "act": "Active",
    "pro": "Prospect",
    "for": "Former"
  },
  gtmSalesPipeMgtLeadTypeByKey: {},

  gtmSalesPipeMgtLeadStatus: {

    "opn"   : "Open",
    "wrk"   : "Working",
    "met"   : "Meeting Set",
    "won"   : "Won",
    "lost"  : "Lost",
    "col"   : "Cold",
    "ntqual": "Not Qualified",
    "nur"   : "Nurture",
    "inact" : "Inactive"
  },
  gtmSalesPipeMgtLeadStatusByKey: {},

  gtmSalesPipeMgtLeadSourcedChannel: {

    "web"   : "Website",
    "email" : "Email",
    "link"  : "LinkedIn Campaign",
    "otrsoc": "Other Social Campaign",
    "netev" : "Networking Event",
    "virev" : "Virtual Event",
    "dirout": "Direct Outreach",
    "adv"   : "Advocacy",
    "parchn": "Partner-Channel/Tech",
    "othon" : "Other-Online",
    "othoff": "Other-Offline"
  },
  gtmSalesPipeMgtLeadSourcedChannelByKey: {},

  gtmSalesPipeMgtOppoDuration: {

    "les1" : "<1 month",
    "1"    : "1 month",
    "2"    : "2 months",
    "3"    : "3 months",
    "4"    : "4 months",
    "5"    : "5 months",
    "6"    : "6 months",
    "7"    : "7 months",
    "8"    : "8 months",
    "9"    : "9 months",
    "10"   : "10 months",
    "11"   : "11 months",
    "12"   : "12 months",
    "gtr12": ">12 months"
  },
  gtmSalesPipeMgtOppoDurationByKey: {},

  gtmSalesPipeMgtOppoSize: {

    "les10k"    : "<$10,000",
    "10kt50k"   : "$10,001 - $50,000",
    "50kt100k"  : "$50,001 - $100,000",
    "100kt150k" : "$100,001 - $500,000",
    "150kt1000k": "$500,001 - $1,000,000",
    "gtr1000k"  : ">$1,000,000"
  },
  gtmSalesPipeMgtOppoSizeByKey: {},

  gtmSalesPipeMgtIndus: {

    "bank"   : "Banking & Financial Services",
    "ins"    : "Insurance",
    "health" : "Healthcare",
    "parma"  : "Pharma, Biotech & Life Sciences",
    "travel" : "Travel & Hospitality",
    "retail" : "Retail & Ecommerce",
    "manu"   : "Manufacturing & Logistics",
    "energy" : "Energy & Utilities ",
    "goods"  : "Consumer Goods",
    "media"  : "Media & Entertainment",
    "network": "Telecommunications & Networking",
    "edu"    : "Education & Information Services",
    "tech"   : "Technology & Cutting Edge",
    "auto"   : "Automotive",
    "other"  : "Other"

  },
  gtmSalesPipeMgtIndusByKey: {},

  gtmSalesPipeMgtCusPersonaType: {

    "int"    : "Introducer",
    "user"   : "User",
    "buyer"  : "Buyer",
    "decimak": "Decision Maker",
    "influ"  : "Influncer",
    "gatekep": "Gate Keeper"

  },
  gtmSalesPipeMgtCusPersonaTypeByKey: {},

  gtmSalesPipeMgtColumn: {

    "Account Name"                           : "accName",
    "Lead Type"                              : "leadT",
    "Lead Creation Date"                     : "leadCrDate",
    "Lead Sourced Channel"                   : "leadSrcChn",
    "Lead Status"                            : "leadSts",
    "Lead Owner"                             : "leadOwn",
    "Planned Conversion Date"                : "plandConvDate",
    "Lead Related Comments"                  : "leadRelCom",
    "Oppportunity Size (Potential Revenue $)": "oppSizeRev",
    "Opportunity Size (Billable FTE)"        : "oppSizeB",
    "Opportunity Duration"                   : "oppDu",
    "Primary Customer Contact Person Name"   : "prCusContPer",
    "Customer Designation/Title"             : "cusDesg",
    "Customer Persona Type"                  : "cusPerT",
    "Customer Contact Email"                 : "cusContEm",
    "Customer Contact Phone"                 : "cusContPn",
    "Customer LinkedIn Profile"              : "cusLinkPf",
    "Industry"                               : "inds",
    "Website URL"                            : "url",
    "Company Annual Revenue or Funding URL"  : "compAnnRev",
    "State"                                  : "state",
    "Country"                                : "country",
    "Other Contact Info."                    : "othrCont",
    "Relevant Project Information"           : "relevProjInfo"

  },
  gtmSalesPipeMgtColumnByKey: {},
  
  gtmSalesPipeMgtChartColor: [
    "#008FFB", "#00E396", "#FEB019", "#FF4560", "#775DD0", "#F9CE1D", "#A5978B", "#2B908F", "#2E294E", "#A300D6", "#D7263D"
  ],

  // Need to change in Backend also
  modulePermissionName: {
    "module"            : "t360",
    "businessStrategy"  : "t360:businessStrategy",
    "financialPlan"     : "t360:financialPlan",
    "deliveryOperations": "t360:deliveryOperations",
    "gtmSales"          : "t360:gtmSales"
  },

  // Need to change in Backend also
  moduleName: {

    "business": {
      "oppHypo"        : "opportunityHypothesis",
      "serviceOffering": "servicesOffering",
      "sol"            : "solutions",
      "competitive"    : "competitiveAnalysis"
    },
    "financial": {
      "onRollTeam"     : "onRollTeam",
      "headcountRev"   : "headcountRevenue",
      "bizOpsSummary"  : "businessOperationsSummary",
      "accLevelSummary": "accountLevelSummary",
      "plOutlook"      : "plOutlook",
      "insightsDash"   : "insightsDashboard"
    },
    "operations": {
      "bizOps"    : "bizOps",
      "funGoal"   : "functionLevelGoalSetting",
      "milestones": "milestones",
      "orgGov"    : "orgGovernance",
      "docTemp"   : "documentsTemplates"
    },
    "goToMarket": {
      "salesPlays"                : "salesPlays",
      "targetPersonas"            : "targetPersonas",
      "valProp"                   : "valueProposition",
      "buyerJourney"              : "buyerJourney",
      "positioningStory"          : "positioningHeroTransformationStory",
      "positioningBeforeAfter"    : "positioningBeforeAfter",
      "positioningResearch"       : "positioningResearch",
      "positioningUseCases"       : "positioningUseCases",
      "positioningDifferentiation": "positioningDifferentiation",
      "positioningLongDes"        : "positioningLongerDescription",
      "positioningShortDes"       : "positioningShortDescription",
      "positioningOneLiner"       : "positioningOneLiner",
      "positioningMsg"            : "positioningMessaging",
      "strategicAlliances"        : "strategicAlliances",
      "digMarktContent"           : "digitalMarketingContent",
      "digMarketCampaign"         : "digitalMarketingCampaign",
      "salesPipeMgnt"             : "salesPipelineManagement"
    }

  },
  
  inviteCollaboratorsLimit: 10,
  
  freePlanA: "freePlanA",

  premiumPlanA: "premiumPlanA",

  creditsIcons8: "https://icons8.com"

};

config.initialPermission = {
  [config.modulePermissionName?.module]: {
    "businessStrategy"  : false,
    "financialPlan"     : false,
    "deliveryOperations": false,
    "gtmSales"          : false,
    "collab"            : false,
    "comment"           : false
  },
  [config.modulePermissionName?.businessStrategy]: {
    "customerPersona"      : false,
    "opportunityHypothesis": false,
    "servicesOffering"     : false,
    "solutions"            : false,
    "competitiveAnalysis"  : false
  },
  [config.modulePermissionName?.financialPlan]: {
    "onRollTeam"               : false,
    "headcountRevenue"         : false,
    "businessOperationsSummary": false,
    "accountLevelSummary"      : false,
    "plOutlook"                : false,
    "insightsDashboard"        : false
  },
  [config.modulePermissionName?.deliveryOperations]: {
    "bizOps"                  : false,
    "functionLevelGoalSetting": false,
    "milestones"              : false,
    "orgGovernance"           : false,
    "documentsTemplates"      : false
  },
  [config.modulePermissionName?.gtmSales]: {
    "salesPlays"        : false,
    "targetPersonas"    : false,
    "valueProp"         : false,
    "buyerJourney"      : false,
    "positioning"       : false,
    "strategicAlliances": false,
    "digMarketing"      : false,
    "salesPipelingMgmt" : false
  }
},

Object.keys(config.financialMonth).map(x => {

  config.financialMonthByNumber[config.financialMonth[x]] = x;

}

);
Object.keys(config.financialCurrency).map(x => {

  config.financialCurrencyByKey[config.financialCurrency[x]] = x;

}

);
Object.keys(config.financialEmpTypes).map(x => {

  config.financialEmpTypesByKey[config.financialEmpTypes[x]] = x;

}

);
Object.keys(config.financialEmpLocation).map(x => {

  config.financialEmpLocationByKey[config.financialEmpLocation[x]] = x;

}

);
Object.keys(config.financialEmpBusinessUnits).map(x => {

  config.financialEmpBusinessUnitsByKey[config.financialEmpBusinessUnits[x]] = x;

}

);

Object.keys(config.financialEmpBusinessUnits).map(x => {

  config.financialEmpBusinessUnitsImportByKey[config.financialEmpBusinessUnits[x].toLowerCase()] = x;

}

);
Object.keys(config.financialBillable).map(x => {

  config.financialBillableByKey[config.financialBillable[x]] = x;

}

);
Object.keys(config.financialOnRollTeamColumn).map(x => {

  config.financialOnRollTeamColumnByKey[config.financialOnRollTeamColumn[x]] = x;

}

);

Object.keys(config.financialPipelineAndBillingCategory).map(x => {

  config.financialPipelineAndBillingCategoryByKey[config.financialPipelineAndBillingCategory[x]] = x;

}

);

// GTM Sales Pipeline Management

Object.keys(config.gtmSalesPipeMgtLeadType).map(x => {

  config.gtmSalesPipeMgtLeadTypeByKey[config.gtmSalesPipeMgtLeadType[x]] = x;

}

);

Object.keys(config.gtmSalesPipeMgtLeadStatus).map(x => {

  config.gtmSalesPipeMgtLeadStatusByKey[config.gtmSalesPipeMgtLeadStatus[x]] = x;

}

);

Object.keys(config.gtmSalesPipeMgtLeadSourcedChannel).map(x => {

  config.gtmSalesPipeMgtLeadSourcedChannelByKey[config.gtmSalesPipeMgtLeadSourcedChannel[x]] = x;

}

);

Object.keys(config.gtmSalesPipeMgtOppoDuration).map(x => {

  config.gtmSalesPipeMgtOppoDurationByKey[config.gtmSalesPipeMgtOppoDuration[x]] = x;

}

);

Object.keys(config.gtmSalesPipeMgtOppoSize).map(x => {

  config.gtmSalesPipeMgtOppoSizeByKey[config.gtmSalesPipeMgtOppoSize[x]] = x;

}

);

Object.keys(config.gtmSalesPipeMgtIndus).map(x => {

  config.gtmSalesPipeMgtIndusByKey[config.gtmSalesPipeMgtIndus[x]] = x;

}

);

Object.keys(config.gtmSalesPipeMgtCusPersonaType).map(x => {

  config.gtmSalesPipeMgtCusPersonaTypeByKey[config.gtmSalesPipeMgtCusPersonaType[x]] = x;

}

);

Object.keys(config.gtmSalesPipeMgtColumn).map(x => {

  config.gtmSalesPipeMgtColumnByKey[config.gtmSalesPipeMgtColumn[x]] = x;

}

);


config.routes = {
  
  forgotPassword                  : "/forgot-password",
  register                        : "/register",
  resetPassword                   : "/reset-password",
  login                           : "/login",
  landingPage                     : "/landing-page",
  welcomePage                     : "/welcome-page",
  resendLink                      : "/resend-link",
  redirectPage                    : "/redirect",
  support                         : "/support",
  myProfile                       : "/myProfile",
  feedback                        : "/feedback",
  loginSecurity                   : "/loginSecurity",
  createVenture                   : "/create-venture",
  editVenture                     : "/edit-venture",
  ventureFinished                 : "/venture-finished",
  dashboard                       : "/dashboard",
  verificationLink                : "/verify-link",
  financialInsights               : "/financial/insights",
  financialOnRollTeam             : "/financial/on-roll-team",
  financialPipelineBilling        : "/financial/pipe-and-bill",
  financialAccountLevelSummary    : "/financial/acc-level-summary",
  financialBizOPSSummary          : "/financial/bizops-summary",
  financialPlOutlook              : "/financial/p&l-outlook",
  gtmDashboard                    : "/gtm/dashboard",
  gtmSalesPlays                   : "/gtm/salesplays",
  gtmTargetPersonas               : "/gtm/target-personas",
  gtmValueProp                    : "/gtm/valueprop",
  gtmBuyerJourney                 : "/gtm/buyer-journey",
  gtmPositioning                  : "/gtm/positioning",
  gtmPositioningStory             : "/gtm/positioning-story",
  gtmPositioningBeforeAfter       : "/gtm/positioning-beforeafter",
  gtmPositioningResearch          : "/gtm/positioning-research",
  gtmPositioningUsecases          : "/gtm/positioning-usecases",
  gtmPositioningDifferentiation   : "/gtm/positioning-differentiation",
  gtmPositioningProgressOne       : "/gtm/positioning-progress-one",
  gtmPositioningProgressTwo       : "/gtm/positioning-progress-two",
  gtmPositioningMessage           : "/gtm/positioning-messaging",
  gtmPositioningLongerDescription : "/gtm/positioning-longer-description",
  gtmPositioningShorterDescription: "/gtm/positioning-shorter-description",
  gtmPositioningOneLiner          : "/gtm/positioning-oneliner",
  gtmStrategicAlliances           : "/gtm/strategic-alliances",
  gtmDigitalMarketing             : "/gtm/digital-marketing",
  gtmDigitalContent               : "/gtm-digital-content",
  gtmDigitalCampaign              : "/gtm-digital-campaign",
  gtmSalesPipelineManagement      : "/gtm/sales-pipeline-management",
  gtmTargetUserPersonas           : "/gtm/target-user-personas",
  gtmTargetUserPersonasDetails    : "/gtm/target-user-personas-details",
  pitchdeckIntro                  : "/pitchdeck-into",
  businessDashboard               : "/biz/dashboard",
  opportunityHypothesis           : "/biz/opportunity-hypothesis",
  CustomerPersona                 : "/biz/customer-personas",
  customerUserPersona             : "/biz/customer-user-personas",
  customerUserPersonasDetails     : "/biz/customer-user-personas-details",
  serviceOffering                 : "/biz/service-offering",
  solutions                       : "/biz/solutions",
  competitive                     : "/biz/competitive",
  operationsDashboard             : "/ops/dashboard",
  operationsBizOpsPlan            : "/ops/bizopsplan",
  operationsMilestones            : "/ops/milestones",
  operationsFunctionalGoalSetting : "/ops/functional-goal-setting",
  operationsDocandTemplates       : "/ops/doc-and-template",
  OperationsOrgGovernance         : "/ops/org-governance",
  collaborationLogin              : "/collaboration-login",
  comments                        : "/comments"

};

config.sectionURLWithName = {
  "opportunityHypothesis": {
    "url" : config.routes.opportunityHypothesis,
    "name": "Business Strategy > Opportunity Hypothesis"
  },
  "servicesOffering": {
    "url" : config.routes.serviceOffering,
    "name": "Business Strategy > Service Offeringq"
  },
  "solutions": {
    "url" : config.routes.solutions,
    "name": "Business Strategy > Solutions"
  },
  "onRollTeam": {
    "url" : config.routes.financialOnRollTeam,
    "name": "Financial > On-Roll Team"
  },
  "headcountRevenue": {
    "url" : config.routes.financialPipelineBilling,
    "name": "Financial > Headcount and Revenue Planning"
  },
  "accountLevelSummary": {
    "url" : config.routes.financialAccountLevelSummary,
    "name": "Financial > Account Level Revenue Summary"
  },
  "businessOperationsSummary": {
    "url" : config.routes.financialBizOPSSummary,
    "name": "Financial > Business Operations Summary"
  },
  "plOutlook": {
    "url" : config.routes.financialPlOutlook,
    "name": "Financial > P&L Outlook"
  },
  "insightsDashboard": {
    "url" : config.routes.financialInsights,
    "name": "Financial > Insights Dashboard"
  },
  "bizOps": {
    "url" : config.routes.operationsBizOpsPlan,
    "name": "Operations > BizOps"
  },
  "functionLevelGoalSetting": {
    "url" : config.routes.operationsFunctionalGoalSetting,
    "name": "Operations > Function Level Goal Setting"
  },
  "milestones": {
    "url" : config.routes.operationsMilestones,
    "name": "Operations > Milestones"
  },
  "orgGovernance": {
    "url" : config.routes.OperationsOrgGovernance,
    "name": "Operations > Org.Governance"
  },
  "documentsTemplates": {
    "url" : config.routes.operationsDocandTemplates,
    "name": "Operations > Documents & Templates"
  },
  "salesPlays": {
    "url" : config.routes.gtmSalesPlays,
    "name": "GTM Model > Sales Plays"
  },
  "targetPersonas": {
    "url" : config.routes.gtmTargetPersonas,
    "name": "GTM Model > Segments & Personas"
  },
  "valueProposition": {
    "url" : config.routes.gtmValueProp,
    "name": "GTM Model > Value Proposition"
  },
  "buyerJourney": {
    "url" : config.routes.gtmBuyerJourney,
    "name": "GTM Model > Buyer Journey"
  },
  "positioningHeroTransformationStory": {
    "url" : config.routes.gtmPositioningStory,
    "name": "GTM Model > Positioning > ‘Hero Transformation’ Story"
  },
  "positioningBeforeAfter": {
    "url" : config.routes.gtmPositioningBeforeAfter,
    "name": "GTM Model > Positioning > ‘Hero Transformation’ Story"
  },
  "positioningResearch": {
    "url" : config.routes.gtmPositioningResearch,
    "name": "GTM Model > Positioning > Research"
  },
  "positioningUseCases": {
    "url" : config.routes.gtmPositioningUsecases,
    "name": "GTM Model > Positioning > Use Cases"
  },
  "positioningDifferentiation": {
    "url" : config.routes.gtmPositioningDifferentiation,
    "name": "GTM Model > Positioning > Proof Points & Differentiators"
  },
  "positioningLongerDescription": {
    "url" : config.routes.gtmPositioningLongerDescription,
    "name": "GTM Model > Positioning"
  },
  "positioningShortDescription": {
    "url" : config.routes.gtmPositioningShorterDescription,
    "name": "GTM Model > Positioning"
  },
  "positioningOneLiner": {
    "url" : config.routes.gtmPositioningOneLiner,
    "name": "GTM Model > Positioning"
  },
  "positioningMessaging": {
    "url" : config.routes.gtmPositioningMessage,
    "name": "GTM Model > Positioning > Value Messages"
  },
  "strategicAlliances": {
    "url" : config.routes.gtmStrategicAlliances,
    "name": "GTM Model > Strategic Alliances"
  },
  "digitalMarketingContent": {
    "url" : config.routes.gtmDigitalContent,
    "name": "GTM Model > Digital Marketing > Digital Content"
  },
  "digitalMarketingCampaign": {
    "url" : config.routes.gtmDigitalCampaign,
    "name": "GTM Model > Digital Marketing > Digital Campaign"
  },
  "salesPipelineManagement": {
    "url" : config.routes.gtmSalesPipelineManagement,
    "name": "GTM Model > Sales Pipeline Management"
  }
};


config.lastPageIgnoreList = [
  config.routes.forgotPassword,
  config.routes.register,
  config.routes.resetPassword,
  config.routes.login,
  config.routes.landingPage,
  config.routes.resendLink,
  "/",
  config.routes.redirectPage,
  config.routes.collaborationLogin
];

config.userWithEditorAccess = [ "EDITOR", "ADMIN" ];

config.ventureCommenter = "COMMENTER";
config.ventureEditor = "EDITOR";

config.financialOnROllTeamSampleRecords = {
  "empId"   : "10001",
  "empN"    : "John Doe",
  "desig"   : "Software Engineer",
  "expert"  : "Node.js",
  "loc"     : "offshore",
  "acc"     : "",
  "pro"     : "Acme",
  "repMan"  : "Tom Doe",
  "empT"    : "permanent",
  "bu"      : "delivery",
  "billable": "billable",
  "monComp" : 4000,
  "monBene" : 2000
};


config.financialPipelineAndBillingSampleRecords = [
  {
    "cat"   : "existing",
    "accN"  : "Account 1",
    "des"   : "Account description here",
    "loc"   : "offshore",
    "conf"  : 75,
    "rateHr": 20,
    "jan"   : 1,
    "feb"   : 1,
    "mar"   : 1,
    "apr"   : 1,
    "may"   : 1,
    "jun"   : 1,
    "jul"   : 1,
    "aug"   : 1,
    "sep"   : 1,
    "oct"   : 1,
    "nov"   : 1,
    "dec"   : 1,
    "revTot": 38400,
    "revJan": 3200,
    "revFeb": 3200,
    "revMar": 3200,
    "revApr": 3200,
    "revMay": 3200,
    "revJun": 3200,
    "revJul": 3200,
    "revAug": 3200,
    "revSep": 3200,
    "revOct": 3200,
    "revNov": 3200,
    "revDec": 3200
  }
];

config.financialPipelineAndBillingFixedRows = [
  {
    "emptyCellRemoveBorder": true,
    "conf"                 : ""
  },
  {
    "emptyCellRemoveBorder": true,
    "rateHr"               : "Total Billable HC Projection",
    "tooltip"              : "This row shows the total billable headcount (HC) projection for every month.",
    "jan"                  : 0,
    "feb"                  : 0,
    "mar"                  : 0,
    "apr"                  : 0,
    "may"                  : 0,
    "jun"                  : 0,
    "jul"                  : 0,
    "aug"                  : 0,
    "sep"                  : 0,
    "oct"                  : 0,
    "nov"                  : 0,
    "dec"                  : 0,
    "revTot"               : 0,
    "revJan"               : 0,
    "revFeb"               : 0,
    "revMar"               : 0,
    "revApr"               : 0,
    "revMay"               : 0,
    "revJun"               : 0,
    "revJul"               : 0,
    "revAug"               : 0,
    "revSep"               : 0,
    "revOct"               : 0,
    "revNov"               : 0,
    "revDec"               : 0
  },
  {
    "emptyCellRemoveBorder": true,
    "rateHr"               : "HC Projection with 20% Bench",
    "tooltip"              : "This row shows the billable headcount (HC) projection for every month considering a bench talent pool of additional 20%. This is typical of any growing tech services firm to keep an additional bench of potential billable talent to proactively plan for market demand. This also helps you to plan recruitment accordingly.",
    "jan"                  : 0,
    "feb"                  : 0,
    "mar"                  : 0,
    "apr"                  : 0,
    "may"                  : 0,
    "jun"                  : 0,
    "jul"                  : 0,
    "aug"                  : 0,
    "sep"                  : 0,
    "oct"                  : 0,
    "nov"                  : 0,
    "dec"                  : 0
  },
  {
    "emptyCellRemoveBorder": true,
    "rateHr"               : "Actual Billable HC - OnRoll",
    "tooltip"              : "This row shows the actual on-roll billable headcount (HC) in your company. This number flows in from the On-Roll Team page. The number reflects the total employees who were marked as ‘Billable’ and ‘Delivery’ in On-Roll Team page.  Employees in ‘BizDev’, ‘BizOps’ are not considered Billable in this context",
    "jan"                  : 0,
    "feb"                  : 0,
    "mar"                  : 0,
    "apr"                  : 0,
    "may"                  : 0,
    "jun"                  : 0,
    "jul"                  : 0,
    "aug"                  : 0,
    "sep"                  : 0,
    "oct"                  : 0,
    "nov"                  : 0,
    "dec"                  : 0
  },
  {
    "emptyCellRemoveBorder": true,
    "rateHr"               : "Billable Utilization %",
    "tooltip"              : "This row shows the Billable Utilization %. Formula 🡪 (‘Total Billable HC Projection’) / (‘Actual Billable HC - OnRoll’)* 100. It is ideal to keep the Utilization % at 70-80% for a growing tech services firm (as you will have a deployable bench talent pool)",
    "jan"                  : 0,
    "feb"                  : 0,
    "mar"                  : 0,
    "apr"                  : 0,
    "may"                  : 0,
    "jun"                  : 0,
    "jul"                  : 0,
    "aug"                  : 0,
    "sep"                  : 0,
    "oct"                  : 0,
    "nov"                  : 0,
    "dec"                  : 0
  }
];

// Account level summary

config.financialAccountSummaryFixedRows = [
  {
    "emptyCellRemoveBorder": true,
    "accN"                 : "Total Revenue",
    "py"                   : 0,
    "cy"                   : 0,
    "fy"                   : 0
  }
];

// BizOps summary

config.financialBizOpsSummaryFixedRows = bizOpsData.rowData;

config.financialBizOpsSummaryDefaultLabels = {

  "bizOpsExp": {

    "leadership" : "Leadership & Mgmt. Team",
    "bizDevTeam" : "Biz Dev Team (Sales & Marketing)",
    "bizOpsTeam" : "Biz Ops Team (HR, FinOps, Admin)",
    "offSpace"   : "Office Space",
    "infraServer": "Hardware, Server Infrastructure Expenses",
    "marketing"  : "Marketing Spend",
    "serviceProv": "Service Providers Spend",
    "venPartExp" : "Vendors & Partners Expenses",
    "insurance"  : "Insurance",
    "legalFee"   : "Legal & Financial Fees",
    "consultFee" : "Consultations & Fees",
    "buffer"     : "Buffer/Misc/cont.",
    "webMaintExp": "Web Hosting & Maintence Expenses",
    "envSetup"   : "Environment Set up & Onboarding Costs",
    "softLic"    : "3rd party software, licenses, tools & transaction fees",
    "inventory"  : "Inventory Costs",
    "infraMach"  : "Infra, Equipment & Machinery Costs",
    "purchaseMat": "Purchase & Materials Cost",
    "procurement": "Procurement costs",
    "dutiesTrans": "Duties & Transaction fees",
    "other"      : "Other"

  },

  "travelEnt": {
    "leadershipTE": "Leadership & Mgmt. Team",
    "delivery"    : "Delivery Team",
    "bizDevTeamTE": "Biz Dev Team (Sales & Marketing)",
    "bizOpsTeamTE": "Biz OPS Team (HR, FinOps, Admin)"
  }
};

// PlOutlook

config.financialPlOutlookFixedRows = plOutlook.rowData;

config.financialPlOutlookTooltip = {

  "extTotal" : "The revenue numbers from existing accounts flows in from ‘Headcount and Revenue Planning’ page.",
  "pipeTotal": "The revenue numbers from pipeline accounts flows in from ‘Headcount and Revenue Planning’ page.",
  "deli"     : "The Talent Overhead cost for Delivery function flows in from the ‘On-Roll Team’ page. Note - The cost is summed-up for those employees marked in ‘Delivery",
  "grossProf": "Total Gross Revenue’ minus ‘Talent Overhead – Delivery",
  "grossMarg": "(‘Gross Profit’ / ‘Total Gross Revenue’) * 100",
  "bd"       : "The Talent overhead for BizDev function flows in from the On-Roll Team page, for those employees marked under BizDev function",
  "bo"       : "The Talent overhead for BizOps function flows in from the On-Roll Team page, for those employees marked under BizOps function",
  "travelEnt": "The T&E cost numbers flows in from ‘Business Operations Summary’ page from the ‘Total T&E Expenses’ line item",
  "bizOpsExp": "The Business Operations cost numbers flows in from ‘Business Operations Summary’ page from the ‘Total Business Operating Expenses’ line item",
  "ebit"     : "Gross Profit minus Grand Total Business Operations Costs",
  "netIncome": "EBIT – (General Taxes + Interests)",
  "netMargin": "(Net Income / Total Gross Revenue) * 100"

};


export default config;
