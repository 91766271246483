import { createSlice } from "@reduxjs/toolkit";

interface VentureType {
  ventureId?: string,
  userId?: string,
  details?: VentureDetails,
  business?: any,
  financials?: any,
  gtm?: any,
  operations?: any,
  userRole?: any
}

interface AppVentureType {
  venture: VentureType
}

interface VentureDetails {

  name: string,
  location: string,
  website: string,
  linkedin: string,
  otherSocialMedia: string,
  logo: any,
  businessEmail: string,
  businessContact: string,
  journeyQuestions: VentureJourneyQuestions

  }

interface VentureJourneyQuestions {

  likeTo: string,
  companySize: string,
  companyServices: string,
  companyServicesOther: string

  }

const initialStateValues: VentureType = {
  ventureId: "",
  userId   : "",
  details  : {
    name            : "",
    location        : "",
    website         : "",
    linkedin        : "",
    otherSocialMedia: "",
    logo            : "",
    businessEmail   : "",
    businessContact : "",
    journeyQuestions: {
      likeTo              : "",
      companySize         : "",
      companyServices     : "",
      companyServicesOther: ""
    }
  },
  business  : {},
  financials: {},
  gtm       : {},
  operations: {},
  userRole  : "Owner"
};

// set data functions

const setVentureId = (state: any, actions: any) => {

  state.ventureId = actions.payload;

};
const setUserId = (state: any, actions: any) => {

  state.userId = actions.payload;

};
const setDetails = (state: any, actions: any) => {

  state.details = actions.payload;

};
const setBusiness = (state: any, actions: any) => {

  state.business = actions.payload;

};
const setFinancials = (state: any, actions: any) => {

  state.financials = actions.payload;

};
const setGtm = (state: any, actions: any) => {

  state.gtm = actions.payload;

};
const setOperations = (state: any, actions: any) => {

  state.operations = actions.payload;

};
const setUserRole = (state: any, actions: any) => {

  state.userRole = actions.payload;

};

const setVenture = (state: any, actions: any) => {

  state.ventureId = actions.payload.ventureId;
  state.userId = actions.payload.userId;
  state.details = actions.payload.details;
  state.business = actions.payload.business;
  state.financials = actions.payload.financials;
  state.gtm = actions.payload.gtm;
  state.operations = actions.payload.operations;
  state.userRole = actions.payload.userRole;

};

const VentureSlice = createSlice({
  name        : "Venture",
  initialState: initialStateValues,

  reducers: {
    setVentureId : setVentureId,
    setUserId    : setUserId,
    setDetails   : setDetails,
    setBusiness  : setBusiness,
    setFinancials: setFinancials,
    setGtm       : setGtm,
    setOperations: setOperations,
    setUserRole  : setUserRole,
    setVenture   : setVenture
  }
});

export const getVentureId = (state: AppVentureType) => state.venture?.ventureId;
export const getUserId = (state: AppVentureType) => state.venture?.userId;
export const getDetails = (state: AppVentureType) => state.venture?.details;
export const getMarket = (state: AppVentureType) => state.venture?.business;
export const getFinancials = (state: AppVentureType) => state.venture?.financials;
export const getGtm = (state: AppVentureType) => state.venture?.gtm;
export const getOperations = (state: AppVentureType) => state.venture?.operations;
export const getUserRole = (state: AppVentureType) => state.venture?.userRole;
export const getVenture = (state: AppVentureType) => state.venture;

export default VentureSlice;
