import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import config from "./configs/config";
import "./App.css";
import "./AgGrid.css";
import { Route, Routes } from "react-router-dom";
import LandingPage from "./pages/LandingPage/LandingPage";
import { AuthContextProvider } from "./context/AuthContext";
import { useLocation } from "react-router-dom";
import _ from "lodash";
// import { useMediaQuery } from "react-responsive";
// import WarningMessage from "./components/WarningMessage";

const DefaultLayout = React.lazy(() => import("./containers/Layout/DefaultLayout"));


function App() {

  const location: any = useLocation();
  // const isTabletOrMobile: any = useMediaQuery({ query: "(max-width: 1023px)" });

  useEffect(() => {

    if (location && !_.isEmpty(location) && !config.lastPageIgnoreList?.includes(window.location.pathname)) {

      location.href = window.location.href;
      location.host = window.location.host;

      setTimeout(() => {

        sessionStorage.setItem("LastPage", JSON.stringify(location));

      }, 1000);

    }

  }, [ location ]);

  return (
    <>
      {/* { isTabletOrMobile ? */}
      {/* <WarningMessage /> : */}
      <AuthContextProvider>
        <Routes>

          <Route path="/" element={<LandingPage />} />
          <Route path="*" element={<DefaultLayout />} />

        </Routes>
      </AuthContextProvider>
      
      {/* } */}

    </>

  );

}

export default App;
