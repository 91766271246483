import { createSlice } from "@reduxjs/toolkit";

interface UserType {
  userVenturePermission?: string
}

interface AppUserType {
 permission: UserType
}

const initialStateValues: UserType = {
  userVenturePermission: ""
};

// set data functions

const setUserVenturePermission = (state: any, actions: any) => {

  state.userVenturePermission = actions.payload;

};

const setPermission = (state: any, actions: any) => {

  state.userVenturePermission = actions.payload.userVenturePermission;

};

const Permission = createSlice({

  name: "Permission",

  initialState: initialStateValues,

  reducers: {
    setUserVenturePermission: setUserVenturePermission,
    setPermission           : setPermission
  }
});

export const getUserVenturePermission = (state: AppUserType) => state.permission?.userVenturePermission;
export const getPermission = (state: AppUserType) => state.permission;

export default Permission;
