import React from "react";

import { createContext, useContext, useEffect, useState } from "react";
import { auth } from "../firebase";

import {
  GoogleAuthProvider,
  signInWithPopup,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  sendPasswordResetEmail
} from "firebase/auth";


type Context = {
  createUser: any;
  users: any;
  logout: any;
  signIn: any;
  token: any;
  googleSignIn: any;
  forgotPassword: any;
};

let authTokenRefereshDemon = undefined;

if (authTokenRefereshDemon == undefined) {
  
  authTokenRefereshDemon = setInterval(() => {

    if (auth && auth.currentUser) {

      // console.info(JSON.parse(JSON.stringify(auth.currentUser))?.stsTokenManager.expirationTime, Date.now());

      if (Date.now() > JSON.parse(JSON.stringify(auth.currentUser))?.stsTokenManager.expirationTime - 5 * 60 * 1000) {

        auth.currentUser?.getIdToken(true);

      }

    }

  }, 1 * 60 * 1000);

}

const UserContext = createContext({} as Context);

export const AuthContextProvider = ({ children }: any) => {

  const [ users, setUsers ] = useState<any>({});
  const [ token, setToken ] = useState<any>({});
  // const [ tokenDetails, setTokenDetails ] = useState<any>({});

  const createUser = (email: any, password: any) => {

    return createUserWithEmailAndPassword(auth, email, password);
  
  };

  const signIn = (email: any, password: any) => {

    return signInWithEmailAndPassword(auth, email, password);
  
  };

  const googleSignIn = async () => {

    const provider = new GoogleAuthProvider();

    await signInWithPopup(auth, provider);
  
  };

  const logout = () => {

    return signOut(auth);
  
  };
  const forgotPassword = (email: any) => {

    return sendPasswordResetEmail(auth, email);
  
  };

  useEffect(() => {

    const unsubscribe = onAuthStateChanged(auth, async (currentUser: any) => {

      setUsers(currentUser);

      // setTimeout(() => {

      //   auth.currentUser?.getIdToken(true);

      //   console.info(JSON.parse(JSON.stringify(auth.currentUser)));

      //   setTimeout(() => {

      //     console.info(JSON.parse(JSON.stringify(auth.currentUser)));
  
      //   }, 3000);

      // }, 3000);
    
    });

    
    return () => {

      unsubscribe();
    
    };
  
  }, []);


  // useEffect(() => {
    
  //   if (users && users.accessToken && tokenDetails && tokenDetails.expirationTime) {
      
      
  //     setInterval(() => {
        
  //       const expiresMS = new Date(tokenDetails.expirationTime).getTime();
  
  //       const remainingTimeMs = expiresMS  - Date.now();

  //       console.info(remainingTimeMs)
      
  //       if (remainingTimeMs < 150000 ) {
              
  //         users.getIdTokenResult().then((token: any) => {

  //           setToken(token.token);
            
  //           console.info("Access Token Changes");
          
  //         })
        
  //       }
      
  //     }, 10000)
      
  //   }

  // }, [ ]);


  useEffect(() => {

    if (users && users.accessToken) {

      users.getIdTokenResult().then((tokenDetails: any) => {

        setToken(tokenDetails.token);
        // setTokenDetails(tokenDetails);

      });

    }

  
  }, [ users ]);

  return (
    <UserContext.Provider value={{ createUser, token, users, logout, signIn, googleSignIn, forgotPassword }}>
      {children}
    </UserContext.Provider>
  );

};

export const UserAuth = () => {

  return useContext(UserContext);

};
