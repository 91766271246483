import { createSlice } from "@reduxjs/toolkit";

interface CommentModeType {
 isCommentOpen?: boolean
 sectionName?: string
 comments?: any
}

interface AppCommentModeType {
  CommentMode: CommentModeType
 }

const initialStateValues: CommentModeType = {
  isCommentOpen: false,
  sectionName  : "",
  comments     : {}

};

// set data functions

const setIsCommentOpen = (state: any, actions: any) => {

  state.isCommentOpen = actions.payload;

};
const setSectionName = (state: any, actions: any) => {

  state.sectionName = actions.payload;

};

const setSectionComments = (state: any, actions: any) => {

  state.comments = actions.payload;

};

const CommentMode = createSlice({

  name: "CommentMode",

  initialState: initialStateValues,

  reducers: {
    setIsCommentOpen  : setIsCommentOpen,
    setSectionName    : setSectionName,
    setSectionComments: setSectionComments
  }
});

export const getIsCommentOpen = (state: AppCommentModeType) => state.CommentMode.isCommentOpen;
export const getSectionName = (state: AppCommentModeType) => state.CommentMode.sectionName;
export const getSectionComments = (state: AppCommentModeType) => state.CommentMode.comments;

export default CommentMode;
